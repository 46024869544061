/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
export enum BSCoreDataDistributorsEnumsDocumentType {
  StockReceipt = "StockReceipt",
  Invoice = "Invoice",
  DebitNote = "DebitNote",
  CreditNote = "CreditNote",
}

export enum BSDALBeerPortEnumsTransportEntityStatusEnum {
  Expected = "Expected",
  Arrived = "Arrived",
  ProcessUnloading = "ProcessUnloading",
  WaitingForLoading = "WaitingForLoading",
  ProcessLoading = "ProcessLoading",
  Loaded = "Loaded",
  Left = "Left",
  InMiddleDepot = "InMiddleDepot",
  InDepot = "InDepot",
}

export enum BSDALErrorLoggerEnumsErrorTypeEnum {
  DistributorFiles = "DistributorFiles",
  HermesImport = "HermesImport",
  HermesExport = "HermesExport",
  ApplicationException = "ApplicationException",
  HermesParserJob = "HermesParserJob",
}

export enum BSDALErrorLoggerEnumsFileTypeEnum {
  Customers = "Customers",
  Products = "Products",
  Sales = "Sales",
  Depots = "Depots",
  Skus = "Skus",
  Pocs = "Pocs",
  Suppliers = "Suppliers",
  HermesFile = "HermesFile",
  Stocks = "Stocks",
}

export enum BSDALErrorLoggerHermesExportStatus {
  GenerationInProgress = "GenerationInProgress",
  ExportFailed = "ExportFailed",
  UploadFailed = "UploadFailed",
  FilesUploaded = "FilesUploaded",
  FilesProcessed = "FilesProcessed",
  MissingFiles = "MissingFiles",
  GeneralFileProcessingError = "GeneralFileProcessingError",
}

export enum BSDALMarketplaceEnumsDistributorPriceGroup {
  A = "A",
  B = "B",
  C = "C",
}

export enum BSDALMarketplaceEnumsOrderPackingType {
  Pallet = "Pallet",
  Unit = "Unit",
}

export enum BSDALMarketplaceEnumsOrderStatus {
  New = "New",
  Demand = "Demand",
  Real = "Real",
  InProgress = "InProgress",
}

export enum BSDALMarketplaceEnumsPackagingType {
  CAN = "CAN",
  RGB = "RGB",
  KEG = "KEG",
  OW = "OW",
  PET = "PET",
}

export enum BSDALMarketplaceEnumsPalletGroupType {
  Type1 = "Type1",
  Type2 = "Type2",
  Type3 = "Type3",
  Type4 = "Type4",
  Type5 = "Type5",
  Type6 = "Type6",
  Type7 = "Type7",
  Type8 = "Type8",
  Type9 = "Type9",
}

export enum BSDALMarketplaceEnumsPalletType {
  Industrial = "Industrial",
  Licensed = "Licensed",
}

export type BSCoreServicesDtoMarketplaceImportHistoryDto = {
  /**
   * @format int32
   */
  id?: number;
  fileName?: string | null;
  /**
   * @format date-time
   */
  uploadDate?: string;
  /**
   * @format int32
   */
  uploadedByUserId?: number;
  uploadedByUserName?: string | null;
  /**
   * @format int32
   */
  numberOfImportedRows?: number;
};

export type BSCoreServicesDtoMarketplaceInvoiceProductDistributorDto = {
  /**
   * @format int32
   */
  distributorId?: number;
  distributorName?: string | null;
  /**
   * @format int32
   */
  distributorDepotId?: number;
  distributorDepotName?: string | null;
  /**
   * @format int32
   */
  monthlyLimit?: number;
};

export type BSCoreServicesDtoMarketplaceOrderDto = {
  /**
   * @format int32
   */
  id?: number | null;
  status?: BSDALMarketplaceEnumsOrderStatus;
  /**
   * @format date-time
   */
  deliveryDate?: string;
  /**
   * @format date-time
   */
  dateCreated?: string;
  distributorName?: string | null;
  distributorSapPayerId?: string | null;
  depotName?: string | null;
  depotAddress?: string | null;
  depotContactName?: string | null;
  depotContactPhone?: string | null;
  depotSapShipToId?: string | null;
  depotSapSoldToId?: string | null;
  /**
   * @format int32
   */
  userId?: number;
  /**
   * @format int32
   */
  depotId?: number;
  total?: BSCoreServicesDtoMarketplaceOrderTotalDto;
  products?: BSCoreServicesDtoMarketplaceOrderProductDto[] | null;
  /**
   * @format int32
   */
  demandOrderId?: number | null;
};

export type BSCoreServicesDtoMarketplaceOrderProductDto = {
  /**
   * @format int32
   */
  id?: number;
  isInvoiceProduct?: boolean;
  /**
   * @format int32
   */
  productId?: number;
  productName?: string | null;
  /**
   * @format int32
   */
  productUnitsInPallet?: number;
  /**
   * @format double
   */
  productVolumeInHL?: number;
  /**
   * @format double
   */
  productPrice?: number;
  productGroupType?: BSDALMarketplaceEnumsPalletGroupType;
  /**
   * @format double
   */
  productWeight?: number;
  /**
   * @format int32
   */
  productSortIndex?: number;
  productImgUrl?: string | null;
  pallet?: BSCoreServicesDtoMarketplacePalletDto;
  package?: BSCoreServicesDtoMarketplacePackagingDto;
  orderPackingType?: BSDALMarketplaceEnumsOrderPackingType;
  /**
   * @format int32
   */
  orderedQuantities?: number;
  /**
   * @format int32
   */
  orderedUnits?: number;
  /**
   * @format double
   */
  totalUnitVolumeInHL?: number;
  /**
   * @format double
   */
  totalBeerCostWithoutVatInBGN?: number;
  /**
   * @format double
   */
  orderPallets?: number;
  /**
   * @format int32
   */
  orderPalletsRounded?: number;
  /**
   * @format double
   */
  totalPackagingCostInBGN?: number;
  /**
   * @format double
   */
  totalBeerAndPackagingCostWithVat?: number;
  /**
   * @format double
   */
  weightOfOrderUnit?: number;
  /**
   * @format int32
   */
  currentAvailableUnits?: number | null;
  /**
   * @format date-time
   */
  expiryDate?: string | null;
  /**
   * @format int32
   */
  onInvoiceQuantityLimit?: number | null;
  /**
   * @format int32
   */
  multiPackQuantityLimit?: number | null;
  /**
   * @format date-time
   */
  availabilityDate?: string | null;
  importComment?: string | null;
  barcode?: string | null;
  invoicePromoCode?: string | null;
  sapNumber?: string | null;
  isMultiPack?: boolean;
};

export type BSCoreServicesDtoMarketplaceOrderTotalDto = {
  /**
   * @format double
   */
  ttlVolumeInHL?: number;
  /**
   * @format double
   */
  ttlBeerCostWithoutVATBGN?: number;
  /**
   * @format double
   */
  ttlBeerCostWithVATBGN?: number;
  /**
   * @format double
   */
  ttlPackagingCostInBGN?: number;
  /**
   * @format double
   */
  ttlBeerWithVATAndPackagingCostInBGN?: number;
  /**
   * @format double
   */
  ttlTruckAreaFactor?: number;
  /**
   * @format double
   */
  ttlWeight?: number;
  /**
   * @format int32
   */
  ttlPallets?: number;
  /**
   * @format int32
   */
  ttlPalletCountIndustrialType?: number;
  /**
   * @format int32
   */
  ttlPalletCountLicensedType?: number;
};

export type BSCoreServicesDtoMarketplacePackagingDto = {
  /**
   * @format int32
   */
  id?: number;
  name?: string | null;
  /**
   * @format double
   */
  price?: number;
  type?: BSDALMarketplaceEnumsPackagingType;
};

export type BSCoreServicesDtoMarketplacePalletDto = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @minLength 3
   */
  barcode: string;
  /**
   * @maximum 999
   * @minimum 0
   * @format double
   */
  weight: number;
  /**
   * @maximum 9999
   * @minimum 0
   * @format double
   */
  price: number;
  type?: BSDALMarketplaceEnumsPalletType;
};

export type BSCoreServicesDtoMarketplaceProductAvailabilityDto = {
  /**
   * @format date-time
   */
  expiryDate?: string | null;
  /**
   * @format int32
   */
  currentAvailableUnits?: number;
  importComment?: string | null;
  /**
   * @format int32
   */
  nextAvailableUnits?: number | null;
  /**
   * @format date-time
   */
  nextAvailableUnitsDate?: string | null;
};

export type BSCoreServicesDtoMarketplaceProductDistributorDto = {
  /**
   * @format int32
   */
  distributorId?: number;
  distributorName?: string | null;
  isSelected?: boolean;
  orderPackingType?: BSDALMarketplaceEnumsOrderPackingType;
  /**
   * @format int32
   */
  multiPackLimit?: number | null;
};

export type BSCoreServicesDtoMarketplaceProductDistributorPriceGroupDto = {
  priceGroup?: BSDALMarketplaceEnumsDistributorPriceGroup;
  /**
   * @format double
   */
  price?: number;
};

export type BSCoreServicesDtoMarketplaceProductDto = {
  /**
   * @format int32
   */
  id?: number;
  name?: string | null;
  sapNumber?: string | null;
  barcode?: string | null;
  /**
   * @format int32
   */
  unitsInPallet?: number;
  /**
   * @format double
   */
  volumeInHL?: number;
  /**
   * @format double
   */
  weight?: number;
  /**
   * @format int32
   */
  sortIndex?: number;
  isActive?: boolean;
  groupType?: BSDALMarketplaceEnumsPalletGroupType;
  imgUrl?: string | null;
  availability?: BSCoreServicesDtoMarketplaceProductAvailabilityDto;
  pallet?: BSCoreServicesDtoMarketplacePalletDto;
  packaging?: BSCoreServicesDtoMarketplacePackagingDto;
  isInvoiceProduct?: boolean;
  prices?: BSCoreServicesDtoMarketplaceProductDistributorPriceGroupDto[] | null;
  isMultiPack?: boolean;
};

export type BSCoreDataDTOHermesHermesSku = Record<string, any>;

export type BSCoreDataDTOPaginationPaginationDataBase = {
  /**
   * @format int32
   */
  offset?: number;
  /**
   * @format int32
   */
  limit?: number;
};

export type BSCoreDataDTOPaginationPaginationDataDistributorCustomer = {
  /**
   * @format int32
   */
  offset?: number;
  /**
   * @format int32
   */
  limit?: number;
};

export type BSCoreDataDTOPaginationPaginationDataHermesPOC = {
  /**
   * @format int32
   */
  offset?: number;
  /**
   * @format int32
   */
  limit?: number;
};

export type BSCoreDataDTOPaginationPaginationDataHermesSKUs = {
  /**
   * @format int32
   */
  offset?: number;
  /**
   * @format int32
   */
  limit?: number;
};

export type BSCoreDataDTOPaginationPaginationDataPOCMapping = {
  /**
   * @format int32
   */
  offset?: number;
  /**
   * @format int32
   */
  limit?: number;
};

export type BSCoreDataDTOPaginationPaginationDataSKUMapping = {
  /**
   * @format int32
   */
  offset?: number;
  /**
   * @format int32
   */
  limit?: number;
};

export type BSCoreDataDTOPaginationPaginationFilterDistributorCustomer = Record<
  string,
  any
>;

export type BSCoreDataDTOPaginationPaginationFilterPOCMapping = Record<
  string,
  any
>;

export type BSCoreDataDistributorsXmlModelsDistributorCustomerXmlModelDCustomers =
  Record<string, any>;

export type BSCoreDataDistributorsXmlModelsDistributorProductXmlModelDProducts =
  Record<string, any>;

export type BSCoreDataDistributorsXmlModelsDistributorSaleXmlModelDSales =
  Record<string, any>;

export type BSCoreWebRequestsRequestsChangePasswordRequest = {
  email?: string | null;
  resetPasswordCode?: string | null;
  newPassword?: string | null;
};

export type BSCoreWebRequestsRequestsCommentRequest = {
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  userId?: number;
  /**
   * @format int32
   */
  distributorProductId?: number | null;
  /**
   * @format int32
   */
  distributorCustomerId?: number | null;
  text?: string | null;
};

export type BSCoreWebRequestsRequestsDistributorsDistributorCustomerRequest = {
  /**
   * @format int32
   */
  tenantId?: number | null;
  customerCode?: string | null;
  customerName?: string | null;
  vatNumber?: string | null;
  townOfDispatching?: string | null;
  addressOfDispatching?: string | null;
  addressNumberOfDispatching?: string | null;
  contactPerson?: string | null;
  depotCode?: string | null;
  /**
   * @format date-time
   */
  systemDate?: string;
  /**
   * @format int32
   */
  distributorId?: number | null;
  /**
   * @format int64
   */
  fileId?: number | null;
  /**
   * @format int32
   */
  position?: number;
};

export type BSCoreWebRequestsRequestsDistributorsDistributorDepotRequest = {
  /**
   * @format int32
   */
  tenantId?: number | null;
  depotCode?: string | null;
  depotName?: string | null;
  officialDepotName?: string | null;
  region?: string | null;
  exportFolder?: string | null;
  /**
   * @format int32
   */
  distributorId?: number | null;
  isActive?: boolean | null;
  sapShipToId?: string | null;
  sapSoldToId?: string | null;
  deliveryAddress?: string | null;
  contactPersonName?: string | null;
  contactPersonPhone?: string | null;
  softwareProviderCompanyName?: string | null;
  softwareProviderPhone?: string | null;
  softwareProviderEmail?: string | null;
  softwareProviderContactPerson?: string | null;
  /**
   * @format int32
   */
  hermesDepotId?: number | null;
};

export type BSCoreWebRequestsRequestsDistributorsDistributorFilePeriodRequest =
  {
    /**
     * @format date-time
     */
    fromDate?: string;
    /**
     * @format date-time
     */
    toDate?: string;
    depotIds?: string[] | null;
  };

export type BSCoreWebRequestsRequestsDistributorsDistributorFileRequest = {
  /**
   * @format int32
   */
  tenantId?: number | null;
  fileName?: string | null;
  fileType?: string | null;
  /**
   * @format int32
   */
  totalEntries?: number;
  /**
   * @format date-time
   */
  lastModifiedDate?: string;
  /**
   * @format date-time
   */
  extractDate?: string | null;
  /**
   * @format int32
   */
  distributorId?: number | null;
  /**
   * @format int32
   */
  depotId?: number | null;
  /**
   * @format int32
   */
  datesCount?: number;
  datesList?: string | null;
  status?: string | null;
  description?: string | null;
  fileChecksum?: string | null;
  /**
   * @format int32
   */
  totalSalesQuantity?: number | null;
};

export type BSCoreWebRequestsRequestsDistributorsDistributorProductRequest = {
  /**
   * @format int32
   */
  tenantId?: number | null;
  productCode?: string | null;
  productDescription?: string | null;
  kamenitzaProductCode?: string | null;
  barcode?: string | null;
  /**
   * @format int32
   */
  inventoryQuantity?: number;
  descriptionOfUnitQuantity?: string | null;
  distributorDepotCode?: string | null;
  /**
   * @format date-time
   */
  systemDate?: string;
  /**
   * @format int32
   */
  distributorId?: number | null;
  /**
   * @format int32
   */
  distributorDepotId?: number | null;
  /**
   * @format int64
   */
  fileId?: number | null;
  /**
   * @format int32
   */
  position?: number;
};

export type BSCoreWebRequestsRequestsDistributorsDistributorRequest = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format int32
   */
  tenantId?: number | null;
  code?: string | null;
  name?: string | null;
  officialDistributorName?: string | null;
  sapPayerId?: string | null;
  priceGroup?: BSDALMarketplaceEnumsDistributorPriceGroup;
  /**
   * @format int32
   */
  hermesSupplierId?: number | null;
};

export type BSCoreWebRequestsRequestsHermesDepotRequest = {
  /**
   * @minLength 1
   */
  hermesId: string;
  /**
   * @format int32
   */
  hermesSupplierRtmId?: number;
  /**
   * @format int32
   */
  tenantId?: number | null;
  /**
   * @minLength 1
   */
  name: string;
  city?: string | null;
  county?: string | null;
  street?: string | null;
  houseNumber?: string | null;
};

export type BSCoreWebRequestsRequestsHermesPocMappingRequest = {
  /**
   * @format int32
   */
  pocId?: number;
  /**
   * @format int32
   */
  customerId?: number;
  /**
   * @format int32
   */
  depotId?: number;
  /**
   * @format date-time
   */
  fromDate?: string | null;
  /**
   * @format date-time
   */
  toDate?: string | null;
};

export type BSCoreWebRequestsRequestsHermesPocRequest = {
  /**
   * @minLength 1
   */
  hermesId: string;
  /**
   * @minLength 1
   */
  name: string;
  street?: string | null;
  houseNumber?: string | null;
  zipCode?: string | null;
  city?: string | null;
  county?: string | null;
  /**
   * @format double
   */
  latitude?: number | null;
  /**
   * @format double
   */
  longitude?: number | null;
  customerFiscalCode?: string | null;
  channelName?: string | null;
  assignedSrLinkId?: string | null;
  assignedSrName?: string | null;
  assignedSrEmail?: string | null;
  supervisorLinkId?: string | null;
  supervisorName?: string | null;
  supervisorEmail?: string | null;
  /**
   * @format int32
   */
  hermesCustomerId?: number;
  pocStatus?: string | null;
  /**
   * @format int32
   */
  deleted?: number;
  /**
   * @format int32
   */
  tenantId?: number | null;
};

export type BSCoreWebRequestsRequestsHermesSkuMappingRequest = {
  /**
   * @format int32
   */
  skuId?: number;
  /**
   * @format int32
   */
  productId?: number;
  /**
   * @format int32
   */
  depotId?: number;
  isFreeCase?: boolean;
  /**
   * @format date-time
   */
  fromDate?: string | null;
  /**
   * @format date-time
   */
  toDate?: string | null;
};

export type BSCoreWebRequestsRequestsHermesSkuRequest = {
  /**
   * @minLength 1
   */
  hermesId: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  hermesProductCode?: string | null;
  /**
   * @format int32
   */
  conversionFactor?: number;
  /**
   * @minLength 1
   */
  name: string;
  packagingType?: string | null;
};

export type BSCoreWebRequestsRequestsManageProfileRequest = {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
};

export type BSCoreWebRequestsRequestsReportsHermesReportRequest = {
  depotCodes?: string[] | null;
  /**
   * @format date-time
   */
  fromDate?: string;
  /**
   * @format date-time
   */
  toDate?: string;
};

export type BSCoreWebRequestsRequestsReportsRawDataReportRequest = {
  depotCode?: string | null;
  reportType?: string | null;
  /**
   * @format date-time
   */
  fromDate?: string;
  /**
   * @format date-time
   */
  toDate?: string;
};

export type BSCoreWebRequestsRequestsReportsWhsDataReportRequest = {
  /**
   * @format int32
   */
  month?: number;
  /**
   * @format int32
   */
  year?: number;
  regions?: string[] | null;
};

export type BSCoreWebRequestsRequestsReportsWhsSaleDataReportRequest = {
  depotCodes?: string[] | null;
  type?: string | null;
  /**
   * @format date-time
   */
  fromDate?: string;
  /**
   * @format date-time
   */
  toDate?: string;
};

export type BSCoreWebRequestsRequestsReportsWhsStockReportRequest = {
  depotCodes?: string[] | null;
  reportType?: string | null;
  /**
   * @format date-time
   */
  date?: string;
  /**
   * @format date-time
   */
  dateStart?: string;
  /**
   * @format date-time
   */
  dateEnd?: string;
};

export type BSCoreWebRequestsRequestsTenantRequest = {
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  name?: string | null;
};

export type BSCoreWebRequestsRequestsTenantSupervisorDepotsRequest = {
  /**
   * @format int32
   */
  userIdx?: number;
  /**
   * @format int32
   */
  tenantIdx?: number | null;
  depotIds?: number[] | null;
};

export type BSCoreWebRequestsRequestsUserLoginRequest = {
  /**
   * @minLength 1
   */
  username: string;
  /**
   * @minLength 1
   */
  password: string;
};

export type BSCoreWebRequestsRequestsUserRequest = {
  username?: string | null;
  password?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  /**
   * @format int32
   */
  tenantId?: number | null;
  /**
   * @format int32
   */
  distributorId?: number | null;
  /**
   * @format int32
   */
  roleId?: number;
};

export type BSCoreWebRequestsResponsesCommentResponse = {
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  id?: number;
  title?: string | null;
  authorType?: string | null;
  distributorProductName?: string | null;
  distributorCustomerName?: string | null;
  subtitle?: string | null;
  unread?: boolean;
  /**
   * @format date-time
   */
  date?: string;
};

export type BSCoreWebRequestsResponsesDistributorsDistributorCustomerResponse =
  {
    /**
     * @format date-time
     */
    dateCreated?: string;
    /**
     * @format date-time
     */
    dateUpdated?: string;
    /**
     * @format int32
     */
    id?: number;
    /**
     * @format int32
     */
    tenantId?: number | null;
    code?: string | null;
    depotName?: string | null;
    name?: string | null;
    vat?: string | null;
    townOfDispatching?: string | null;
    addressOfDispatching?: string | null;
    addressNumberOfDispatching?: string | null;
    contactPerson?: string | null;
    /**
     * @format int32
     */
    distributorId?: number | null;
    /**
     * @format date-time
     */
    systemDate?: string;
    /**
     * @format int32
     */
    depotId?: number;
    /**
     * @format int64
     */
    distributorFileId?: number | null;
    /**
     * @format int32
     */
    position?: number;
  };

export type BSCoreWebRequestsResponsesDistributorsDistributorDepotResponse = {
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format int32
   */
  tenantId?: number | null;
  depotCode?: string | null;
  depotName?: string | null;
  officialDepotName?: string | null;
  region?: string | null;
  exportFolder?: string | null;
  /**
   * @format int32
   */
  distributorId?: number | null;
  isActive?: boolean;
  sapShipToId?: string | null;
  sapSoldToId?: string | null;
  deliveryAddress?: string | null;
  contactPersonName?: string | null;
  contactPersonPhone?: string | null;
  softwareProviderCompanyName?: string | null;
  softwareProviderPhone?: string | null;
  softwareProviderEmail?: string | null;
  softwareProviderContactPerson?: string | null;
  /**
   * @format int32
   */
  hermesDepotId?: number | null;
};

export type BSCoreWebRequestsResponsesDistributorsDistributorFileResponse = {
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int64
   */
  id?: number;
  /**
   * @format int32
   */
  tenantId?: number | null;
  fileName?: string | null;
  depotName?: string | null;
  fileType?: string | null;
  /**
   * @format int32
   */
  totalEntries?: number;
  /**
   * @format date-time
   */
  lastModifiedDate?: string;
  /**
   * @format int32
   */
  distributorId?: number | null;
  status?: string | null;
  description?: string | null;
  /**
   * @format int32
   */
  datesCount?: number;
  datesList?: string | null;
  fileChecksum?: string | null;
  userName?: string | null;
  /**
   * @format int32
   */
  depotId?: number | null;
  /**
   * @format date-time
   */
  extractDate?: string | null;
  /**
   * @format int32
   */
  totalSalesQuantity?: number | null;
};

export type BSCoreWebRequestsResponsesDistributorsDistributorProductResponse = {
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format int32
   */
  tenantId?: number | null;
  code?: string | null;
  /**
   * @format int32
   */
  depotId?: number;
  depotName?: string | null;
  description?: string | null;
  kamenitzaProductCode?: string | null;
  barcode?: string | null;
  hasFreeCase?: boolean;
  /**
   * @format int32
   */
  distributorId?: number | null;
  /**
   * @format int64
   */
  fileId?: number | null;
  /**
   * @format int32
   */
  position?: number;
};

export type BSCoreWebRequestsResponsesDistributorsDistributorResponse = {
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format int32
   */
  tenantId?: number | null;
  code?: string | null;
  name?: string | null;
  officialDistributorName?: string | null;
  sapPayerId?: string | null;
  isActive?: boolean;
  /**
   * @format int32
   */
  hermesSupplierId?: number | null;
  priceGroup?: BSDALMarketplaceEnumsDistributorPriceGroup;
  depots?: BSDALBeerStreamModelsDistributorDistributorDepot[] | null;
};

export type BSCoreWebRequestsResponsesDistributorsPageResponse1BSCoreDataDTOHermesHermesSku =
  {
    /**
     * @format date-time
     */
    dateCreated?: string;
    /**
     * @format date-time
     */
    dateUpdated?: string;
    data?: BSCoreDataDTOHermesHermesSku[] | null;
    searchSuccess?: boolean;
    /**
     * @format int32
     */
    dataTotalSize?: number;
  };

export type BSCoreWebRequestsResponsesDistributorsPageResponse1BSCoreWebRequestsResponsesDistributorsDistributorCustomerResponse =
  {
    /**
     * @format date-time
     */
    dateCreated?: string;
    /**
     * @format date-time
     */
    dateUpdated?: string;
    data?:
      | BSCoreWebRequestsResponsesDistributorsDistributorCustomerResponse[]
      | null;
    searchSuccess?: boolean;
    /**
     * @format int32
     */
    dataTotalSize?: number;
  };

export type BSCoreWebRequestsResponsesDistributorsPageResponse1BSCoreWebRequestsResponsesDistributorsDistributorFileResponse =
  {
    /**
     * @format date-time
     */
    dateCreated?: string;
    /**
     * @format date-time
     */
    dateUpdated?: string;
    data?:
      | BSCoreWebRequestsResponsesDistributorsDistributorFileResponse[]
      | null;
    searchSuccess?: boolean;
    /**
     * @format int32
     */
    dataTotalSize?: number;
  };

export type BSCoreWebRequestsResponsesDistributorsPageResponse1BSCoreWebRequestsResponsesHermesPocResponse =
  {
    /**
     * @format date-time
     */
    dateCreated?: string;
    /**
     * @format date-time
     */
    dateUpdated?: string;
    data?: BSCoreWebRequestsResponsesHermesPocResponse[] | null;
    searchSuccess?: boolean;
    /**
     * @format int32
     */
    dataTotalSize?: number;
  };

export type BSCoreWebRequestsResponsesDistributorsPageResponse1BSDALHelpersPOCMappingResponseWithInfo =
  {
    /**
     * @format date-time
     */
    dateCreated?: string;
    /**
     * @format date-time
     */
    dateUpdated?: string;
    data?: BSDALHelpersPOCMappingResponseWithInfo[] | null;
    searchSuccess?: boolean;
    /**
     * @format int32
     */
    dataTotalSize?: number;
  };

export type BSCoreWebRequestsResponsesDistributorsPageResponse1BSDALHelpersSkuMappingsWithInfo =
  {
    /**
     * @format date-time
     */
    dateCreated?: string;
    /**
     * @format date-time
     */
    dateUpdated?: string;
    data?: BSDALHelpersSkuMappingsWithInfo[] | null;
    searchSuccess?: boolean;
    /**
     * @format int32
     */
    dataTotalSize?: number;
  };

export type BSCoreWebRequestsResponsesHermesHermesExportResponse = {
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format date-time
   */
  startTime?: string;
  /**
   * @format date-time
   */
  endTime?: string | null;
  status?: BSDALErrorLoggerHermesExportStatus;
  /**
   * @format int32
   */
  distributorsSentCount?: number;
  /**
   * @format int32
   */
  hermesDepotsCount?: number;
  exportFileName?: string | null;
};

export type BSCoreWebRequestsResponsesHermesPocMappingResponse = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format int32
   */
  pocId?: number;
  /**
   * @format int32
   */
  customerId?: number;
  /**
   * @format int32
   */
  depotId?: number;
  /**
   * @format date-time
   */
  fromDate?: string | null;
  /**
   * @format date-time
   */
  toDate?: string | null;
};

export type BSCoreWebRequestsResponsesHermesPocResponse = {
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  id?: number;
  hermesId?: string | null;
  /**
   * @format int32
   */
  tenantId?: number | null;
  pocName?: string | null;
  city?: string | null;
  county?: string | null;
  street?: string | null;
  zipCode?: string | null;
  houseNumber?: string | null;
  /**
   * @format double
   */
  latitude?: number | null;
  /**
   * @format double
   */
  longitude?: number | null;
  customerFiscalCode?: string | null;
  chanelName?: string | null;
  assignedSrLinkId?: string | null;
  assignedSrName?: string | null;
  assignedSrEmail?: string | null;
  supervisorLinkId?: string | null;
  supervisorName?: string | null;
  supervisorEmail?: string | null;
  /**
   * @format int32
   */
  hermesCustomerId?: number;
  hermesCustomerName?: string | null;
  pocStatus?: string | null;
  /**
   * @format int32
   */
  deleted?: number;
  /**
   * @format int32
   */
  rank?: number;
};

export type BSCoreWebRequestsResponsesHermesSkuMappingResponse = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format int32
   */
  skuId?: number;
  /**
   * @format int32
   */
  productId?: number;
  /**
   * @format int32
   */
  depotId?: number;
  /**
   * @format date-time
   */
  fromDate?: string | null;
  /**
   * @format date-time
   */
  toDate?: string | null;
  isActive?: boolean;
  isFreeCase?: boolean;
};

export type BSCoreWebRequestsResponsesHermesSkuMappingResponseWithInfo = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format int32
   */
  skuId?: number;
  skuName?: string | null;
  skuCode?: string | null;
  /**
   * @format int32
   */
  productId?: number;
  productName?: string | null;
  productCode?: string | null;
  /**
   * @format int32
   */
  depotId?: number;
  depotName?: string | null;
  depotCode?: string | null;
  /**
   * @format date-time
   */
  fromDate?: string | null;
  /**
   * @format date-time
   */
  toDate?: string | null;
  isActive?: boolean;
  isFreeCase?: boolean;
};

export type BSCoreWebRequestsResponsesHermesSkuResponse = {
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  id?: number;
  hermesId?: string | null;
  /**
   * @format int32
   */
  conversionFactor?: number;
  /**
   * @format int32
   */
  tenantId?: number | null;
  name?: string | null;
  packagingType?: string | null;
  hermesProductId?: string | null;
  hermesProductName?: string | null;
};

export type BSCoreWebRequestsResponsesTenantResponse = {
  /**
   * @format int32
   */
  id?: number;
  name?: string | null;
  isActive?: boolean;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
};

export type BSCoreWebRequestsResponsesUserResponse = {
  /**
   * @format int32
   */
  userId?: number;
  username?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  /**
   * @format int32
   */
  tenantId?: number | null;
  /**
   * @format int32
   */
  distributorId?: number | null;
  /**
   * @format int32
   */
  roleId?: number;
  isActive?: boolean;
};

export type BSDALBeerPortModelsCompany = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  code?: string | null;
  name?: string | null;
  description?: string | null;
  isActive?: boolean;
  trucks?: BSDALBeerPortModelsTruck[] | null;
  transportEntities?: BSDALBeerPortModelsTransportEntity[] | null;
};

export type BSDALBeerPortModelsRamp = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  rampName?: string | null;
  isFree?: boolean | null;
  transportEntities?: BSDALBeerPortModelsTransportEntity[] | null;
};

export type BSDALBeerPortModelsRole = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  roleName?: string | null;
  roleTransportEntityStatuses?:
    | BSDALBeerPortModelsRoleTransportEntityStatus[]
    | null;
};

export type BSDALBeerPortModelsRoleTransportEntityStatus = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  roleId?: number;
  role?: BSDALBeerPortModelsRole;
  /**
   * @format int32
   */
  transportEntityStatusId?: number;
  transportEntityStatus?: BSDALBeerPortModelsTransportEntityStatus;
};

export type BSDALBeerPortModelsTransportEntity = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format date-time
   */
  plannedDate?: string;
  /**
   * @format int32
   */
  hour?: number;
  /**
   * @format int32
   */
  companyId?: number;
  company?: BSDALBeerPortModelsCompany;
  /**
   * @format int32
   */
  truckId?: number;
  truck?: BSDALBeerPortModelsTruck;
  isActive?: boolean;
  note?: string | null;
  /**
   * @format int32
   */
  rampId?: number | null;
  ramp?: BSDALBeerPortModelsRamp;
  /**
   * @format int32
   */
  transportEntityStatusId?: number;
  transportEntityStatus?: BSDALBeerPortModelsTransportEntityStatus;
  /**
   * @format date-time
   */
  departureDateTime?: string | null;
  additionalNote?: string | null;
  transportEntityDepots?: BSDALBeerPortModelsTransportEntityDepot[] | null;
  transportEntityTransportEntityStatuses?:
    | BSDALBeerPortModelsTransportEntityTransportEntityStatus[]
    | null;
};

export type BSDALBeerPortModelsTransportEntityDepot = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  distributorDepotId?: number;
  distributorDepot?: BSDALBeerStreamModelsDistributorDistributorDepot;
  /**
   * @format int32
   */
  transportEntityId?: number;
  transportEntity?: BSDALBeerPortModelsTransportEntity;
  /**
   * @format int32
   */
  order?: number;
  /**
   * @format date-time
   */
  arrivedInDepot?: string | null;
  /**
   * @format date-time
   */
  leftDepot?: string | null;
};

export type BSDALBeerPortModelsTransportEntityStatus = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  status?: BSDALBeerPortEnumsTransportEntityStatusEnum;
  roleTransportEntityStatuses?:
    | BSDALBeerPortModelsRoleTransportEntityStatus[]
    | null;
  transportEntities?: BSDALBeerPortModelsTransportEntity[] | null;
  transportEntityTransportEntityStatuses?:
    | BSDALBeerPortModelsTransportEntityTransportEntityStatus[]
    | null;
};

export type BSDALBeerPortModelsTransportEntityTransportEntityStatus = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  transportEntityId?: number;
  /**
   * @format int32
   */
  transportEntityStatusId?: number;
  /**
   * @format int32
   */
  transportEntityOldStatusId?: number | null;
  /**
   * @format date-time
   */
  fromDate?: string | null;
  /**
   * @format date-time
   */
  toDate?: string | null;
  /**
   * @format int32
   */
  userId?: number;
  transportEntities?: BSDALBeerPortModelsTransportEntity;
  transportEntityStatuses?: BSDALBeerPortModelsTransportEntityStatus;
  user?: BSDALBeerPortModelsUser;
};

export type BSDALBeerPortModelsTruck = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  code?: string | null;
  registrationNumber?: string | null;
  description?: string | null;
  isActive?: boolean;
  /**
   * @format int32
   */
  companyId?: number;
  company?: BSDALBeerPortModelsCompany;
  transportEntities?: BSDALBeerPortModelsTransportEntity[] | null;
};

export type BSDALBeerPortModelsUser = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  userName?: string | null;
  password?: string | null;
  salt?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  role?: BSDALBeerPortModelsRole;
  /**
   * @format int32
   */
  roleId?: number;
  phoneNumber?: string | null;
  isActive?: boolean;
};

export type BSDALBeerStreamDTODateRangeRequest = {
  /**
   * @format date-time
   */
  fromDate?: string | null;
  /**
   * @format date-time
   */
  toDate?: string | null;
};

export type BSDALBeerStreamDTODeletedSalesHistoryRequest = {
  /**
   * @format int32
   */
  offset?: number;
  /**
   * @format int32
   */
  limit?: number;
  filters?: BSDALBeerStreamDTOPaginationFilterDistributorErrorFile;
};

export type BSDALBeerStreamDTODistributorFileErrorRequest = {
  /**
   * @format int32
   */
  offset?: number;
  /**
   * @format int32
   */
  limit?: number;
  filters?: BSDALBeerStreamDTOPaginationFilterDistributorErrorFile;
};

export type BSDALBeerStreamDTOPaginationFilterDistributorErrorFile = {
  fileType?: BSDALErrorLoggerEnumsFileTypeEnum;
  errorType?: BSDALErrorLoggerEnumsErrorTypeEnum;
};

export type BSDALBeerStreamModelsComment = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  userId?: number;
  text?: string | null;
  /**
   * @format int32
   */
  distributorProductId?: number | null;
  /**
   * @format int32
   */
  distributorCustomerId?: number | null;
  isActive?: boolean;
  isSeen?: boolean;
  distributorCustomer?: BSDALBeerStreamModelsDistributorDistributorCustomer;
  distributorProduct?: BSDALBeerStreamModelsDistributorDistributorProduct;
  user?: BSDALBeerStreamModelsUser;
};

export type BSDALBeerStreamModelsDistributorDistributor = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  /**
   * @maxLength 20
   * @minLength 0
   */
  code?: string | null;
  /**
   * @maxLength 50
   * @minLength 0
   */
  name?: string | null;
  /**
   * @maxLength 128
   * @minLength 0
   */
  officialDistributorName?: string | null;
  /**
   * @maxLength 128
   * @minLength 0
   */
  sapPayerId?: string | null;
  /**
   * @format int32
   */
  hermesSupplierId?: number | null;
  isActive?: boolean;
  priceGroup?: BSDALMarketplaceEnumsDistributorPriceGroup;
  depots?: BSDALBeerStreamModelsDistributorDistributorDepot[] | null;
  products?: BSDALBeerStreamModelsDistributorDistributorProduct[] | null;
  customers?: BSDALBeerStreamModelsDistributorDistributorCustomer[] | null;
  productInStock?:
    | BSDALBeerStreamModelsDistributorDistributorProductInStock[]
    | null;
  productDistributorAvailabilities?:
    | BSDALMarketplaceModelsProductDistributorAvailability[]
    | null;
  invoiceProductDistributorAvailabilities?:
    | BSDALMarketplaceModelsInvoiceProductDistributorAvailability[]
    | null;
};

export type BSDALBeerStreamModelsDistributorDistributorCustomer = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  /**
   * @maxLength 50
   * @minLength 0
   */
  code?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  name?: string | null;
  /**
   * @maxLength 20
   * @minLength 0
   */
  vat?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  townOfDispatching?: string | null;
  addressOfDispatching?: string | null;
  /**
   * @maxLength 10
   * @minLength 0
   */
  addressNumberOfDispatching?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  contactPerson?: string | null;
  /**
   * @format date-time
   */
  systemDate?: string;
  /**
   * @format int32
   */
  position?: number | null;
  /**
   * @maxLength 50
   * @minLength 0
   */
  depotCode?: string | null;
  /**
   * @format int32
   */
  depotId?: number | null;
  depot?: BSDALBeerStreamModelsDistributorDistributorDepot;
  /**
   * @format int64
   */
  distributorFileId?: number | null;
  distributorFile?: BSDALBeerStreamModelsDistributorDistributorFile;
  /**
   * @format int32
   */
  distributorId?: number | null;
  distributor?: BSDALBeerStreamModelsDistributorDistributor;
  sales?: BSDALBeerStreamModelsDistributorDistributorSale[] | null;
};

export type BSDALBeerStreamModelsDistributorDistributorDepot = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  /**
   * @maxLength 50
   * @minLength 0
   */
  depotCode?: string | null;
  name?: string | null;
  /**
   * @maxLength 128
   * @minLength 0
   */
  officialDepotName?: string | null;
  isActive?: boolean;
  saveOrdersToFtp?: boolean;
  /**
   * @maxLength 128
   * @minLength 0
   */
  contactPersonName?: string | null;
  /**
   * @maxLength 128
   * @minLength 0
   */
  contactPersonPhone?: string | null;
  softwareProviderCompanyName?: string | null;
  softwareProviderPhone?: string | null;
  softwareProviderEmail?: string | null;
  softwareProviderContactPerson?: string | null;
  notes?: string | null;
  /**
   * @maxLength 128
   * @minLength 0
   */
  sapShipToId?: string | null;
  /**
   * @maxLength 128
   * @minLength 0
   */
  sapSoldToId?: string | null;
  /**
   * @maxLength 128
   * @minLength 0
   */
  deliveryAddress?: string | null;
  region?: string | null;
  /**
   * @format double
   */
  latitude?: number | null;
  /**
   * @format double
   */
  longitude?: number | null;
  /**
   * @format int32
   */
  radius?: number | null;
  address?: string | null;
  /**
   * @maxLength 250
   * @minLength 0
   */
  exportFolder?: string | null;
  /**
   * @format int32
   */
  distributorId?: number | null;
  distributor?: BSDALBeerStreamModelsDistributorDistributor;
  /**
   * @format int32
   */
  hermesDepotId?: number | null;
  hermesDepot?: BSDALBeerStreamModelsHermesHermesDepot;
  products?: BSDALBeerStreamModelsDistributorDistributorProduct[] | null;
  productInStock?:
    | BSDALBeerStreamModelsDistributorDistributorProductInStock[]
    | null;
  sales?: BSDALBeerStreamModelsDistributorDistributorSale[] | null;
  tenantSupervisorDepotAssociations?:
    | BSDALBeerStreamModelsDistributorTenantSupervisorDepotAssociation[]
    | null;
  invoiceProductDistributorAvailabilities?:
    | BSDALMarketplaceModelsInvoiceProductDistributorAvailability[]
    | null;
  transportEntityDepots?: BSDALBeerPortModelsTransportEntityDepot[] | null;
};

export type BSDALBeerStreamModelsDistributorDistributorFile = {
  /**
   * @format int64
   */
  id?: number;
  /**
   * @maxLength 100
   * @minLength 0
   */
  fileName?: string | null;
  /**
   * @maxLength 50
   * @minLength 0
   */
  fileType?: string | null;
  /**
   * @format int32
   */
  totalEntries?: number;
  /**
   * @format date-time
   */
  lastModifiedDate?: string;
  /**
   * @format date-time
   */
  extractionDate?: string | null;
  status?: string | null;
  /**
   * @maxLength 200
   * @minLength 0
   */
  description?: string | null;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  datesCount?: number;
  datesList?: string | null;
  md5Checksum?: string | null;
  /**
   * @format int32
   */
  depotId?: number | null;
  depot?: BSDALBeerStreamModelsDistributorDistributorDepot;
  /**
   * @format int32
   */
  distributorId?: number | null;
  distributor?: BSDALBeerStreamModelsDistributorDistributor;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  /**
   * @format int32
   */
  userId?: number | null;
  user?: BSDALBeerStreamModelsUser;
  /**
   * @format int32
   */
  totalSalesQuantity?: number | null;
  distributorFileEntries?:
    | BSDALBeerStreamModelsDistributorDistributorFileEntry[]
    | null;
};

export type BSDALBeerStreamModelsDistributorDistributorFileEntry = {
  /**
   * @format int64
   */
  id?: number;
  /**
   * @format int32
   */
  position?: number;
  entryData?: string | null;
  /**
   * @maxLength 50
   * @minLength 0
   */
  status?: string | null;
  error?: string | null;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  /**
   * @format int64
   */
  distributorFileId?: number | null;
  distributorFile?: BSDALBeerStreamModelsDistributorDistributorFile;
};

export type BSDALBeerStreamModelsDistributorDistributorProduct = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  /**
   * @maxLength 50
   * @minLength 0
   */
  code?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  description?: string | null;
  /**
   * @maxLength 20
   * @minLength 0
   */
  kamenitzaProductCode?: string | null;
  /**
   * @maxLength 50
   * @minLength 0
   */
  barcode?: string | null;
  hasFreeCase?: boolean;
  /**
   * @format int32
   */
  position?: number | null;
  /**
   * @format int32
   */
  distributorDepotId?: number | null;
  distributorDepot?: BSDALBeerStreamModelsDistributorDistributorDepot;
  /**
   * @format int32
   */
  distributorId?: number | null;
  distributor?: BSDALBeerStreamModelsDistributorDistributor;
  /**
   * @format int64
   */
  distributorFileId?: number | null;
  distributorFile?: BSDALBeerStreamModelsDistributorDistributorFile;
  sales?: BSDALBeerStreamModelsDistributorDistributorSale[] | null;
};

export type BSDALBeerStreamModelsDistributorDistributorProductInStock = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  /**
   * @format int32
   */
  inventoryQuantity?: number;
  /**
   * @maxLength 5
   * @minLength 0
   */
  descriptionOfUnitQuantity?: string | null;
  /**
   * @format date-time
   */
  systemDate?: string;
  /**
   * @format int32
   */
  position?: number;
  /**
   * @format int32
   */
  productId?: number;
  product?: BSDALBeerStreamModelsDistributorDistributorProduct;
  /**
   * @format int32
   */
  depotId?: number | null;
  depot?: BSDALBeerStreamModelsDistributorDistributorDepot;
  /**
   * @format int64
   */
  distributorFileId?: number | null;
  distributorFile?: BSDALBeerStreamModelsDistributorDistributorFile;
  needsSync?: boolean;
  isUpdated?: boolean;
  stocksHash?: string | null;
};

export type BSDALBeerStreamModelsDistributorDistributorSale = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  /**
   * @maxLength 50
   * @minLength 0
   */
  customerCode?: string | null;
  /**
   * @maxLength 20
   * @minLength 0
   */
  documentNumber?: string | null;
  /**
   * @maxLength 50
   * @minLength 0
   */
  documentSequenceNumber?: string | null;
  /**
   * @maxLength 1000
   * @minLength 0
   */
  documentRowNum?: string | null;
  documentType?: BSCoreDataDistributorsEnumsDocumentType;
  /**
   * @maxLength 2
   * @minLength 0
   */
  movementCode?: string | null;
  /**
   * @maxLength 2
   * @minLength 0
   */
  movementDescription?: string | null;
  /**
   * @format date-time
   */
  transactionDate?: string;
  /**
   * @maxLength 20
   * @minLength 0
   */
  productCode?: string | null;
  /**
   * @format date-time
   */
  deliveryDate?: string;
  /**
   * @format int32
   */
  quantity?: number;
  /**
   * @maxLength 5
   * @minLength 0
   */
  value?: string | null;
  isFreeCase?: boolean;
  /**
   * @maxLength 20
   * @minLength 0
   */
  orderNumber?: string | null;
  /**
   * @maxLength 2
   * @minLength 0
   */
  flag?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  townOfDispatching?: string | null;
  /**
   * @maxLength 200
   * @minLength 0
   */
  addressOfDispatching?: string | null;
  /**
   * @maxLength 20
   * @minLength 0
   */
  addressNumberOfDispatching?: string | null;
  /**
   * @maxLength 20
   * @minLength 0
   */
  depotCode?: string | null;
  /**
   * @format date-time
   */
  systemDate?: string;
  /**
   * @format int32
   */
  position?: number | null;
  /**
   * @format int32
   */
  customerId?: number | null;
  customer?: BSDALBeerStreamModelsDistributorDistributorCustomer;
  /**
   * @format int32
   */
  depotId?: number | null;
  depot?: BSDALBeerStreamModelsDistributorDistributorDepot;
  /**
   * @format int32
   */
  productId?: number | null;
  product?: BSDALBeerStreamModelsDistributorDistributorProduct;
  /**
   * @format int64
   */
  distributorFileId?: number | null;
  distributorFile?: BSDALBeerStreamModelsDistributorDistributorFile;
  /**
   * @format int32
   */
  distributorId?: number | null;
  distributor?: BSDALBeerStreamModelsDistributorDistributor;
  needsSync?: boolean;
  isUpdated?: boolean;
};

export type BSDALBeerStreamModelsDistributorTenantSupervisorDepotAssociation = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  /**
   * @format int32
   */
  userId?: number;
  /**
   * @format int32
   */
  distributorDepotId?: number;
  user?: BSDALBeerStreamModelsUser;
  distributorDepot?: BSDALBeerStreamModelsDistributorDistributorDepot;
};

export type BSDALBeerStreamModelsHermesHermesAddress = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @maxLength 100
   * @minLength 0
   */
  city?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  county?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  street?: string | null;
  /**
   * @maxLength 10
   * @minLength 0
   */
  houseNumber?: string | null;
  /**
   * @maxLength 10
   * @minLength 0
   */
  zipCode?: string | null;
  /**
   * @format double
   */
  latitude?: number | null;
  /**
   * @format double
   */
  longitude?: number | null;
  poc?: BSDALBeerStreamModelsHermesHermesPoc;
  depots?: BSDALBeerStreamModelsHermesHermesDepot;
  customers?: BSDALBeerStreamModelsHermesHermesCustomer;
};

export type BSDALBeerStreamModelsHermesHermesAssignedSr = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  /**
   * @maxLength 50
   * @minLength 0
   */
  linkId?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  name?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  email?: string | null;
  pocs?: BSDALBeerStreamModelsHermesHermesPoc[] | null;
};

export type BSDALBeerStreamModelsHermesHermesCustomer = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  hermesId?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  name?: string | null;
  /**
   * @maxLength 50
   * @minLength 0
   */
  fiscalCode?: string | null;
  /**
   * @format int32
   */
  addressId?: number | null;
  address?: BSDALBeerStreamModelsHermesHermesAddress;
  pocs?: BSDALBeerStreamModelsHermesHermesPoc[] | null;
};

export type BSDALBeerStreamModelsHermesHermesDepot = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  hermesId?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  name?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  hermesLinkId?: string | null;
  /**
   * @format int32
   */
  supplierId?: number | null;
  supplier?: BSDALBeerStreamModelsHermesHermesSupplier;
  /**
   * @format int32
   */
  addressId?: number | null;
  address?: BSDALBeerStreamModelsHermesHermesAddress;
};

export type BSDALBeerStreamModelsHermesHermesPoc = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  hermesId?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  name?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  channelName?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  pocStatus?: string | null;
  deleted?: boolean;
  /**
   * @format int32
   */
  addressId?: number | null;
  address?: BSDALBeerStreamModelsHermesHermesAddress;
  /**
   * @format int32
   */
  customerId?: number | null;
  customer?: BSDALBeerStreamModelsHermesHermesCustomer;
  /**
   * @format int32
   */
  assignedSrId?: number | null;
  assignedSr?: BSDALBeerStreamModelsHermesHermesAssignedSr;
  /**
   * @format int32
   */
  supervisorId?: number | null;
  supervisor?: BSDALBeerStreamModelsHermesHermesSupervisor;
  /**
   * @format int32
   */
  rank?: number;
};

export type BSDALBeerStreamModelsHermesHermesProduct = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  hermesId?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  name?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  brandName?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  packaging?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  packagingType?: string | null;
  skus?: BSDALBeerStreamModelsHermesHermesSku[] | null;
};

export type BSDALBeerStreamModelsHermesHermesSku = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  hermesId?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  name?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  packagingType?: string | null;
  /**
   * @format int32
   */
  conversionFactor?: number;
  productCode?: string | null;
  /**
   * @format int32
   */
  productId?: number | null;
  product?: BSDALBeerStreamModelsHermesHermesProduct;
};

export type BSDALBeerStreamModelsHermesHermesSupervisor = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  /**
   * @maxLength 50
   * @minLength 0
   */
  linkId?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  name?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  email?: string | null;
  pocs?: BSDALBeerStreamModelsHermesHermesPoc[] | null;
};

export type BSDALBeerStreamModelsHermesHermesSupplier = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  rtmId?: string | null;
  /**
   * @maxLength 100
   * @minLength 0
   */
  name?: string | null;
  depots?: BSDALBeerStreamModelsHermesHermesDepot[] | null;
};

export type BSDALBeerStreamModelsRole = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  roleName?: string | null;
};

export type BSDALBeerStreamModelsSkuMapping = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  hermesSkuId?: number;
  /**
   * @format int32
   */
  distributorProductId?: number;
  /**
   * @format int32
   */
  depotId?: number;
  /**
   * @format date-time
   */
  fromDate?: string | null;
  /**
   * @format date-time
   */
  toDate?: string | null;
  isActive?: boolean;
  isFreeCase?: boolean;
  needsSync?: boolean;
  hermesSku?: BSDALBeerStreamModelsHermesHermesSku;
  distributorProduct?: BSDALBeerStreamModelsDistributorDistributorProduct;
  depot?: BSDALBeerStreamModelsDistributorDistributorDepot;
};

export type BSDALBeerStreamModelsTenant = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @maxLength 100
   * @minLength 0
   */
  name?: string | null;
  isActive?: boolean;
  pocs?: BSDALBeerStreamModelsHermesHermesPoc[] | null;
  skus?: BSDALBeerStreamModelsHermesHermesSku[] | null;
  depots?: BSDALBeerStreamModelsHermesHermesDepot[] | null;
  products?: BSDALBeerStreamModelsHermesHermesProduct[] | null;
  customers?: BSDALBeerStreamModelsHermesHermesCustomer[] | null;
  suppliers?: BSDALBeerStreamModelsHermesHermesSupplier[] | null;
};

export type BSDALBeerStreamModelsUser = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format int32
   */
  tenantId?: number | null;
  tenant?: BSDALBeerStreamModelsTenant;
  username?: string | null;
  password?: string | null;
  salt?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  resetPasswordCode?: string | null;
  isActive?: boolean;
  /**
   * @format date-time
   */
  resetPasswordCodeExpiration?: string | null;
  /**
   * @format int32
   */
  distributorId?: number | null;
  distributor?: BSDALBeerStreamModelsDistributorDistributor;
  /**
   * @format int32
   */
  roleId?: number;
  role?: BSDALBeerStreamModelsRole;
  tenantSupervisorDepotAssociations?:
    | BSDALBeerStreamModelsDistributorTenantSupervisorDepotAssociation[]
    | null;
  comments?: BSDALBeerStreamModelsComment[] | null;
};

export type BSDALHelpersPOCMappingResponseWithInfo = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format int32
   */
  hermesPocId?: number;
  /**
   * @format int32
   */
  customerId?: number;
  /**
   * @format int32
   */
  depotId?: number;
  depotName?: string | null;
  customerCode?: string | null;
  customerName?: string | null;
  customerVat?: string | null;
  customerAddressOfDispatching?: string | null;
  hermesPocZipCode?: string | null;
  hermesPocName?: string | null;
  hermesPocStreet?: string | null;
  hermesPocCustomerName?: string | null;
  hermesPocCustomerFiscalCode?: string | null;
  hermesPocStatus?: string | null;
  hermesPocCode?: string | null;
  /**
   * @format date-time
   */
  dateCreated?: string | null;
  /**
   * @format date-time
   */
  fromDate?: string | null;
  /**
   * @format date-time
   */
  toDate?: string | null;
};

export type BSDALHelpersPocMappingLogResponse = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format int32
   */
  pocMappingId?: number | null;
  username?: string | null;
  status?: string | null;
  hermesPocId?: string | null;
  hermesPocName?: string | null;
  hermesId?: string | null;
  /**
   * @format int32
   */
  distributorCustomerId?: number;
  distributorCustomerName?: string | null;
  distributorCustomerCode?: string | null;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format int32
   */
  depotId?: number;
  depotName?: string | null;
};

export type BSDALHelpersSkuMappingsWithInfo = {
  skuMapping?: BSDALBeerStreamModelsSkuMapping;
  product?: BSDALBeerStreamModelsDistributorDistributorProduct;
  depotName?: string | null;
  sku?: BSDALBeerStreamModelsHermesHermesSku;
};

export type BSDALMarketplaceModelsInvoiceProductDistributorAvailability = {
  sapOnInvoicePromoCode?: string | null;
  /**
   * @format int32
   */
  monthlyLimit?: number;
  /**
   * @format int32
   */
  productId?: number;
  product?: BSDALMarketplaceModelsProduct;
  /**
   * @format int32
   */
  distributorId?: number;
  distributor?: BSDALBeerStreamModelsDistributorDistributor;
  /**
   * @format int32
   */
  distributorDepotId?: number;
  distributorDepot?: BSDALBeerStreamModelsDistributorDistributorDepot;
};

export type BSDALMarketplaceModelsOrder = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  status?: BSDALMarketplaceEnumsOrderStatus;
  /**
   * @format int32
   */
  demandOrderId?: number | null;
  /**
   * @format int32
   */
  orderTotalId?: number | null;
  /**
   * @format int32
   */
  depotId?: number;
  /**
   * @format int32
   */
  userId?: number;
  /**
   * @format int32
   */
  orderShippingDetailId?: number;
  demandOrder?: BSDALMarketplaceModelsOrder;
  orderTotal?: BSDALMarketplaceModelsOrderTotal;
  depot?: BSDALBeerStreamModelsDistributorDistributorDepot;
  user?: BSDALBeerStreamModelsUser;
  orderShippingDetail?: BSDALMarketplaceModelsOrderShippingDetail;
  products?: BSDALMarketplaceModelsOrderProduct[] | null;
};

export type BSDALMarketplaceModelsOrderProduct = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  sapNumber?: string | null;
  barcode?: string | null;
  invoicePromoCode?: string | null;
  isInvoiceProduct?: boolean;
  productName?: string | null;
  /**
   * @format int32
   */
  productUnitsInPallet?: number;
  /**
   * @format double
   */
  productVolumeInHL?: number;
  /**
   * @format double
   */
  productPrice?: number;
  productGroupType?: BSDALMarketplaceEnumsPalletGroupType;
  /**
   * @format double
   */
  productWeight?: number;
  /**
   * @format int32
   */
  palletId?: number;
  palletBarcode?: string | null;
  /**
   * @format double
   */
  palletWeight?: number;
  /**
   * @format double
   */
  palletPrice?: number;
  palletType?: BSDALMarketplaceEnumsPalletType;
  /**
   * @format int32
   */
  packagingId?: number;
  packagingName?: string | null;
  /**
   * @format double
   */
  packagingPrice?: number;
  packagingType?: BSDALMarketplaceEnumsPackagingType;
  orderPackingType?: BSDALMarketplaceEnumsOrderPackingType;
  /**
   * @format int32
   */
  orderedQuantities?: number;
  /**
   * @format int32
   */
  orderedUnits?: number;
  /**
   * @format double
   */
  totalUnitVolumeInHL?: number;
  /**
   * @format double
   */
  totalBeerCostWithoutVatInBGN?: number;
  /**
   * @format double
   */
  orderPallets?: number;
  /**
   * @format int32
   */
  orderPalletsRounded?: number;
  /**
   * @format double
   */
  totalPackagingCostInBGN?: number;
  /**
   * @format double
   */
  totalBeerAndPackagingCostWithVat?: number;
  /**
   * @format double
   */
  weightOfOrderUnit?: number;
  /**
   * @format date-time
   */
  expiryDate?: string | null;
  /**
   * @format int32
   */
  orderId?: number;
  /**
   * @format int32
   */
  productId?: number;
  /**
   * @format int32
   */
  reservedQuantityId?: number | null;
  isMultiPack?: boolean;
  importComment?: string | null;
  order?: BSDALMarketplaceModelsOrder;
  product?: BSDALMarketplaceModelsProduct;
  reservedQuantity?: BSDALMarketplaceModelsProductReservedQuantity;
};

export type BSDALMarketplaceModelsOrderShippingDetail = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format date-time
   */
  deliveryDate?: string;
  distributorName?: string | null;
  distributorSapPayerId?: string | null;
  depotName?: string | null;
  depotAddress?: string | null;
  depotContactName?: string | null;
  depotContactPhone?: string | null;
  depotSapShipToId?: string | null;
  depotSapSoldToId?: string | null;
  /**
   * @format int32
   */
  orderId?: number;
  order?: BSDALMarketplaceModelsOrder;
};

export type BSDALMarketplaceModelsOrderTotal = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format double
   */
  ttlVolumeInHL?: number;
  /**
   * @format double
   */
  ttlBeerCostWithoutVATBGN?: number;
  /**
   * @format double
   */
  ttlBeerCostWithVATBGN?: number;
  /**
   * @format double
   */
  ttlPackagingCostInBGN?: number;
  /**
   * @format double
   */
  ttlBeerWithVATAndPackagingCostInBGN?: number;
  /**
   * @format double
   */
  ttlTruckAreaFactor?: number;
  /**
   * @format double
   */
  ttlWeight?: number;
  /**
   * @format int32
   */
  ttlPallets?: number;
  /**
   * @format int32
   */
  ttlPalletCountIndustrialType?: number;
  /**
   * @format int32
   */
  ttlPalletCountLicensedType?: number;
  /**
   * @format int32
   */
  orderId?: number;
  order?: BSDALMarketplaceModelsOrder;
};

export type BSDALMarketplaceModelsPackaging = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  name?: string | null;
  /**
   * @format double
   */
  price?: number;
  type?: BSDALMarketplaceEnumsPackagingType;
};

export type BSDALMarketplaceModelsPallet = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  barcode?: string | null;
  /**
   * @format double
   */
  weight?: number;
  /**
   * @format double
   */
  price?: number;
  type?: BSDALMarketplaceEnumsPalletType;
};

export type BSDALMarketplaceModelsProduct = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  name?: string | null;
  imgUrl?: string | null;
  sapNumber?: string | null;
  barcode?: string | null;
  /**
   * @format int32
   */
  unitsInPallet?: number;
  /**
   * @format double
   */
  volumeInHL?: number;
  /**
   * @format double
   */
  weight?: number;
  /**
   * @format int32
   */
  sortIndex?: number;
  isActive?: boolean;
  isMultiPack?: boolean;
  groupType?: BSDALMarketplaceEnumsPalletGroupType;
  /**
   * @format int32
   */
  productAvailabilityId?: number | null;
  /**
   * @format int32
   */
  palletId?: number;
  /**
   * @format int32
   */
  packageId?: number;
  productAvailability?: BSDALMarketplaceModelsProductAvailability;
  pallet?: BSDALMarketplaceModelsPallet;
  package?: BSDALMarketplaceModelsPackaging;
  productReservedQuantities?:
    | BSDALMarketplaceModelsProductReservedQuantity[]
    | null;
  productDistributorAvailabilities?:
    | BSDALMarketplaceModelsProductDistributorAvailability[]
    | null;
  invoiceProductDistributorAvailabilities?:
    | BSDALMarketplaceModelsInvoiceProductDistributorAvailability[]
    | null;
  distributorPrices?:
    | BSDALMarketplaceModelsProductDistributorGroupPrice[]
    | null;
};

export type BSDALMarketplaceModelsProductAvailability = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  /**
   * @format date-time
   */
  expiryDate?: string | null;
  /**
   * @format int32
   */
  currentAvailableUnits?: number;
  importComment?: string | null;
  /**
   * @format int32
   */
  nextAvailableUnits?: number | null;
  /**
   * @format date-time
   */
  nextAvailableUnitsDate?: string | null;
  /**
   * @format int32
   */
  productId?: number;
  product?: BSDALMarketplaceModelsProduct;
};

export type BSDALMarketplaceModelsProductDistributorAvailability = {
  /**
   * @format int32
   */
  productId?: number;
  product?: BSDALMarketplaceModelsProduct;
  /**
   * @format int32
   */
  distributorId?: number;
  distributor?: BSDALBeerStreamModelsDistributorDistributor;
  orderPackingType?: BSDALMarketplaceEnumsOrderPackingType;
  isSelected?: boolean;
  /**
   * @format int32
   */
  multiPackLimit?: number | null;
};

export type BSDALMarketplaceModelsProductDistributorGroupPrice = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format date-time
   */
  dateCreated?: string;
  /**
   * @format date-time
   */
  dateUpdated?: string;
  priceGroup?: BSDALMarketplaceEnumsDistributorPriceGroup;
  /**
   * @format double
   */
  price?: number;
  /**
   * @format int32
   */
  productId?: number;
  product?: BSDALMarketplaceModelsProduct;
};

export type BSDALMarketplaceModelsProductReservedQuantity = {
  /**
   * @format int32
   */
  id?: number;
  /**
   * @format int32
   */
  orderProductId?: number;
  orderProduct?: BSDALMarketplaceModelsOrderProduct;
  /**
   * @format int32
   */
  productId?: number;
  product?: BSDALMarketplaceModelsProduct;
  /**
   * @format int32
   */
  reservedQuantity?: number;
  /**
   * @format int32
   */
  nextAvailabilityReservedQuantity?: number;
};

export type MicrosoftAspNetCoreMvcProblemDetails = {
  type?: string | null;
  title?: string | null;
  /**
   * @format int32
   */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
} & {
  [key: string]: any;
};

export type SystemIOFile = Record<string, any>;
